<template>
  <McTable
    :isHideMenu="false"
    :isHideAdd="false"
    :isHideSettings="false"
    :isLoading="isFetching"
    :search="search"
    :tabs="tabs"
    :headers="headers"
    :items="items"
    :footerProps="footerProps"
    :paging="paging"
    :pagingOptions="pagingOptions"
    :itemAction="true"
    :showExpand="true"
    :statusBorder="true"
  />
</template>

<script>
// @ is an alias to /src

// Utilities
import {
  // cloneDeep,
  // get
} from 'lodash'

import {
  mapState,
  // mapActions,
  // mapMutations
} from 'vuex'
import {
  REQUEST_STORE_SIGN_LIST,
  UPDATE_STORE_SIGN_STATE,
} from '@/store/modules/storeSigns'
import { initComponent } from '@/lib'


export default initComponent ('Users', {
  isPage: true,
  components : {
    // Chart1: () => import('./components/widgets/ChartOne'),
    McTable: () => import('@/components/base//Table'),
  },
    computed: {
    ...mapState({
      isFetching: (state) => state.storeSigns.isFetching,
      isProcessing: (state) => state.storeSigns.isProcessing,
      items: (state) => state.storeSigns.items,
      paging: (state) => state.storeSigns.paging,
      item: (state) => state.storeSigns.item,
      search: (state) => state.storeSigns.search,
      tabs: (state) => state.storeSigns.tabs,
    }),
    pagingOptions() {
      let option = {
        state: 'storeSigns',
        commit: UPDATE_STORE_SIGN_STATE,
        dispatch: REQUEST_STORE_SIGN_LIST,
      }
      return option;
    }
  },
  data: () => ({
    headers: [
      {
        text: '',
        align: 'center',
        sortable: false,
        value: 'status'
      },
      {
        text: 'Aisle Number',
        align: 'start',
        sortable: false,
        width: '20%',
        value: 'name',
      },
      {
        text: '',
        align: 'center',
        sortable: false,
        value: 'data-table-expand',
        width: 30,
      },
      {
        text: 'Title',
        align: 'center',
        sortable: false,
        value: 'title',
        width: '20%',
      },
      { 
        text: '',
        align: 'right',
        sortable: false,
        value: 'actions',
        width: '80%',
      },
    ],
  }),
  methods: {
    async onReady () {
      const { dispatch } = this.$store
      dispatch(REQUEST_STORE_SIGN_LIST);

      window.setInterval(() => {
        dispatch(REQUEST_STORE_SIGN_LIST);
      }, 60000);
    },
  }
})
</script>

<style lang="sass" scoped>

</style>